import React from 'react';

import { Box, Container, Link, Typography } from '@mui/material';
import LogoImage from '../../../assets/images/logo_footer.png';

const CustomLink = ({ children, href }) => {
    return (
        <li>
            <Link
                fontSize={{ base: 16, sm: 20 }}
                style={{ marginLeft: '-10px', marginTop: '-10px', color: '#fff' }}
                underline="hover"
                href={href}
                target="_blank"
            >
                {children}
            </Link>
        </li>
    );
};
const Footer = () => {
    return (
        <Box style={{ background: '#53c1ed', width: '100%', color: '#fff' }}>
            <Container maxWidth="md" style={{ padding: '28px 16px 16px' }}>
                <Typography fontSize={{ base: 16, sm: 20 }}>しろくま電力（ぱわー）</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', marginTop: '16px' }}>
                    <CustomLink href="https://shirokumapower.com/about">グリーン電力って？</CustomLink>
                    <CustomLink href="https://shirokumapower.com/days">ぱわーくんのしろくまDAYS</CustomLink>
                    <CustomLink href="https://shirokumapower.com/qa">Q&A</CustomLink>
                    <CustomLink href="https://www.epower-portal.com/shirokumapower">マイページ</CustomLink>
                    <CustomLink href="https://shirokumapower.com/shirokuma-inquiry">お問い合わせ</CustomLink>
                    <CustomLink href="https://shirokumapower.com/tokusyou">特定商取引法に基づく表記</CustomLink>
                    <CustomLink href="https://contents.shirokumapower.com/blog">しろくまお役立ちコンテンツ</CustomLink>
                </div>
                <div style={{ display: 'flex', gap: '16px', marginTop: '16px', marginBottom: '20px' }}>
                    <CustomLink href="https://corp.shirokumapower.com/privacy">プライバシーポリシー</CustomLink>
                    <CustomLink href="https://corp.shirokumapower.com">運営会社</CustomLink>
                </div>
                <Link href="https://shirokumapower.com" target="_blank">
                    <img style={{ maxWidth: '220px' }} src={LogoImage} alt="ShirokumaPower" />
                </Link>
                <Typography fontSize={{ base: 16, sm: 20 }} style={{ marginTop: '24px', textAlign: 'center' }}>
                    Copyright© ShirokumaPower Co., Ltd.
                    <br /> All Rights Reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
