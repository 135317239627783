import { ApiFactory } from './Api';

const api = new ApiFactory();

const apiCustomerUrl = process.env.REACT_APP_API_CUSTOMER_URL;

async function checkUrlToken(token, getRegisterStatus, needAuth) {
    const response = await api.post('/registration/token/verified', { token: token, getRegisterStatus, needAuth });
    return response;
}

async function entry(request) {
    const response = await api.post('/registration/entry', request);
    return response.data;
}

async function paymentMethods(request) {
    const response = await api.post('/registration/payment_methods', request);
    return response.data;
}

async function confirmation(request) {
    const response = await api.post('/confirmation', request);
    return response.data;
}

async function verified(request) {
    const response = await api.post('/registration/verified', request);
    return response.data;
}

async function updateInfo(request) {
    const response = await api.put('/registration/accounts', request);
    return response.data;
}

async function check_duplicate(request) {
    const response = await api.post('/registration/contracts/check_duplicate', request);
    return response.data;
}

async function saveCard(request) {
    const response = await api.post(`/transactions/save_card`, request, apiCustomerUrl);
    return response.data;
}

export { checkUrlToken, entry, paymentMethods, confirmation, verified, updateInfo, check_duplicate, saveCard };
