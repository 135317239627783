export const ROUTES = {
    HOME: '/',
    ERROR: '/error/[code]',
    LOGIN: '/login',
    LOGOUT: '/logout',
    ENTRY: '/entry',
    PAYMENT: '/payment',
    CONFIRMATION: '/confirmation',
    CONFIRMATION_SUCCESS: '/confirmation_success',
    EMAIL_VERIFIED: '/email_verified',
    REGISTER_SUCCESS: '/register_success',
    CONTRACT: '/contract',
    SELECT_CONTRACT: '/select_contract',
    EV_INFO: '/ev_info',
    EV_CONFIRMATION: '/ev_confirmation',
    ENTRY_SIMPLE: '/entry_simple',
    SET_PASSWORD: '/set_password',
    UPDATE_INFO: '/update_info',
    THREE_DS_SUCCESS: '/3ds_success'
};
